<template>
  <div v-if="!!serverData">
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Return Configuration</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="createForm" class="py-3 row">
            <div class="h-75px my-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                dense
                outlined
                v-model="formData.integration_id"
                :items="[{ index: 28, text: 'test' }]"
                item-name="text"
                item-value="index"
                label="Integration"
                clearable
                :error-messages="integration_idErrors"
                @input="$v.formData.integration_id.$touch()"
                @blur="$v.formData.integration_id.$touch()"
              ></v-autocomplete>
            </div>
            <div class="h-75px my-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                dense
                outlined
                v-model="formData.type"
                :items="serverData.apply_types"
                item-name="text"
                item-value="index"
                label="Type"
                clearable
                :error-messages="typeErrors"
                @input="$v.formData.type.$touch()"
                @blur="$v.formData.type.$touch()"
              ></v-autocomplete>
            </div>
            <div class="h-75px my-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                dense
                outlined
                v-model="formData.message"
                label="Message"
                required
                clearable
                :error-messages="meassageErrors"
                @input="$v.formData.message.$touch()"
                @blur="$v.formData.message.$touch()"
              ></v-text-field>
            </div>

            <!--end::Body-->
          </form>

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center mt-10">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { editItemMessage } from "@/core/constants/globalVariables";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      integration_id: { required },
      type: { required },
      message: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data() {
    return {
      dialog: false,
      serverData: null,
      formData: {
        integration_id: null,
        type: null,
        message: null,
      },
    };
  },
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = {
        id: { ...this.$store.getters.getItemForAction }.id,
        integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
      };
      ApiService.post("/validations/datahub/return_configuration/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = this.serverData.return_configuration;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.formData;
        ApiService.post(
          "/validations/datahub/return_configuration/update",
          data
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `${editItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        integration_id: null,
        type: null,
        message: null,
      };
      if (this.serverData?.return_configuration) {
        this.formData = this.serverData.return_configuration;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
        this.serverData = null;
      } else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    integration_idErrors() {
      return this.handleFormValidation("integration_id");
    },
    typeErrors() {
      return this.handleFormValidation("type");
    },
    meassageErrors() {
      return this.handleFormValidation("message");
    },
  },
};
</script>
