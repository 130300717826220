<template>
  <v-dialog
    ref="dialog"
    v-model="menu2"
    class="poppins"
    transition="dialog-bottom-transition"
    content-class="mx-0 d-flex justify-content-center align-items-center elevation-0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        label="Date range"
        append-icon="mdi-calendar"
        class="mx-1"
        clearable
        dense
        v-bind="attrs"
        v-on="on"
        :value="inputValue"
        @click:clear="() => resetFilterData(true)"
      />
    </template>
    <template v-slot:default v-if="menu2 === true">
      <date-range-picker
        :linked-calendars="false"
        ref="picker"
        :ranges="false"
        opens="inline"
        :max-date="new Date().toLocaleDateString()"
        :close-on-esc="false"
        :showDropdowns="true"
        :date-format="dateFormat"
        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:MM' }"
        control-container-class="form-control border-0 bg-transparent d-none"
        class="max-w-550px"
        v-model="filterData['date']"
        timePicker
        :time-picker-increment="1"
        @start-selection="onSelection"
      >
        <template v-slot:footer="picker">
          <div
            class="drp-buttons py-4 px-6 poppins d-flex justify-content-between align-items-center"
          >
            <div>
              <v-select
                outlined
                label="Filter by"
                class="poppins w-75"
                v-model="filterData['select']"
                :items="filter.values"
                item-text="text"
                item-value="index"
                dense
                clearable
                hide-details
              />
            </div>

            <div class="d-flex align-center">
              <button
                type="button"
                class="btn btn-light ls1 py-2 px-4"
                @click="
                  () => {
                    picker.clickCancel();
                    handleDialog();
                  }
                "
              >
                Cancel
              </button>
              <!-- <button
                type="button"
                class="btn btn-light ls1 py-2 px-4"
                @click="
                  () => {
                    filterData = {
                      ...filterData,
                      date: { startDate: null, endDate: null },
                    };
                  }
                "
              >
                Clear
              </button> -->
              <button
                type="button"
                class="btn btn--print-upload ls1 py-2 px-3"
                @click="
                  () => {
                    picker.clickApply();
                    handleDialog(picker.rangeText);
                  }
                "
              >
                Apply
              </button>
            </div>
          </div>
        </template>
      </date-range-picker>
    </template>
  </v-dialog>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "FilterDateRangeWithSelectNew",
  components: {
    DateRangePicker,
  },
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    let startDate = new Date();

    const startDatesMonth = startDate.getMonth();
    startDate.setMonth(startDatesMonth - 1);
    startDate.setDate(1);
    startDate.setHours(0, 0);

    let endDate = new Date();
    endDate.setHours(23, 59);

    return {
      filterData: {
        date: { startDate: null, endDate: null },
        select: undefined,
      },
      inputValue: null,
      menu2: false,
      maxDate: "20/5/2024",
      minDate: "01/01/2018",
    };
  },
  mounted() {
    if (this.filterAllData[`${this.filter.name}.select`]) {
      this.filterData["select"] =
        this.filterAllData[`${this.filter.name}.select`];
    } else {
      this.filterData["select"] = this.filter.values[0].index;
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData(isClickClearBtn = false) {
      let startDate = new Date();

      const startDatesMonth = startDate.getMonth();
      startDate.setMonth(startDatesMonth - 1);
      startDate.setDate(1);
      startDate.setHours(0, 0);

      let endDate = new Date();
      endDate.setHours(23, 59);

      this.filterData = {
        date: { startDate: null, endDate },
        select: undefined,
      };

      this.inputValue = null;

      if (isClickClearBtn) {
        const all = this.filterAllData;
        delete all[`${this.filter.name}.date`];
        delete all[`${this.filter.name}.select`];
        this.$store.commit(SET_FILTERS_DATA, all);
      }
    },
    handleDialog(rangeText) {
      if (rangeText) {
        this.inputValue = rangeText;
        const dateArray = rangeText.split(" - ");
        const splitDates = dateArray.map((date) => date.substring(0, 16));
        const all = this.filterAllData;
        all[`${this.filter.name}.date`] = splitDates;
        all[`${this.filter.name}.select`] = this.filterData["select"];
        this.$store.commit(SET_FILTERS_DATA, all);
      } else this.inputValue = "";
      setTimeout(() => {
        this.$refs.picker.togglePicker(false);
        this.menu2 = false;
      }, 100);
    },
    onSelection(dateString) {
      this.filterData.date.startDate = dateString;
    },
    dateFormat(classes, date) {
      if (this.filterData.date.startDate) {
        const now = this.filterData.date.startDate;
        const startOfYesterday = new Date(now);
        startOfYesterday.setDate(now.getDate() - 1);
        startOfYesterday.setHours(0, 0, 0, 0);

        const endOfTomorrow = new Date(now);
        endOfTomorrow.setDate(now.getDate() + 1);
        endOfTomorrow.setHours(23, 59, 59, 999);

        if (!classes.disabled) {
          classes.disabled =
            date.getTime() < startOfYesterday.getTime() ||
            date.getTime() > endOfTomorrow.getTime();
        }
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
.table-condensed {
  // .off {
  //   visibility: hidden;
  // }
  .off.in-range {
    background-color: #ebf4f8 !important;
  }
}
.daterangepicker .drp-calendar td.end-date {
  background-color: rgba(183, 4, 126, 0.8) !important;
}
.calendar-time {
  .form-control {
    padding-left: 11px !important;
  }
}
</style>
