<template>
  <span :class="btnColorSetter(value)">
    {{ value }}
  </span>
</template>

<script>
/**
 * dedicated to use in orders status but used on cod too
 * api name = orderStatus
 * @type [ "badge-light-info", "badge-light-warning", "badge-light-danger", "badge-light-primary", "badge-light-success",]
 */
export default {
  name: "StatusColumn",
  props: ["value", 'customClass'],
  methods: {
    btnColorSetter(tp) {
      let res = this.customClass ?? "text-center badge font-size-sm my-1 py-2 px-4";
      if (tp === "New") {
        return res + " badge-light-primary";
      } else if (tp === "Picked" || tp === "Created") {
        return res + " badge-light-success";
      } else if (tp === "Packed" || tp === "Last Mile") {
        return res + " badge-light-info";
      } else if (tp === "Cancelled") {
        return res + " badge-light-danger";
      } else if (tp === "Shipped") {
        return res + " badge-light-primary";
      } else if (tp === "In Transit" || tp === "Pickup") {
        return res + " badge-light-primary";
      } else if (tp === "Returned" || tp === "Sub") {
        return res + " badge-light-info";
      } else if (tp === "Partially Picked") {
        return res + " badge-light-primary";
      } else if (tp === "Partially Packed") {
        return res + " badge-light-primary";
      } else if (tp === "Out for Delivery") {
        return res + " badge-light-danger";
      } else if (tp === "Delivered") {
        return res + " badge-light-success";
      } else if (tp === "Lost" || tp === "Out For Delivery") {
        return res + " badge-light-danger";
      } else if (tp === "Exception") {
        return res + " badge-light-primary";
      } else if (tp === "Disabled") {
        return res + "  badge-light-danger";
      } else if (tp === "Pending") {
        return res + " badge-light-warning";
      } else if (tp === "Failed") {
        return res + " badge-light-danger";
      } else if (tp === "Sent") {
        return res + " badge-light-success";
      } else if (tp === "Remitted") {
        return res + " badge-light-success";
      } else if (tp === "Confirmed") {
        return res + " badge-light-success";
      } else if (tp === "Collected" || tp === "Rts Request") {
        return res + " badge-light-primary";
      } else if (tp === "Refunded") {
        return res + " badge-light-success"; // order status ends
      } else if (tp === "Active") {
        return res + " badge-light-success";
      } else if (tp === "Inactive") {
        return res + " badge-light-danger";
      } else if (tp === "Verified") {
        return res + " badge-light-success";
      } else if (tp === "Unverified") {
        return res + " badge-light-danger";
      } else if (tp === "Suspended") {
        return res + " badge-light-warning";
      } else if (tp === true) {
        return res + " badge-light-info";
      } else if (tp === false) {
        return res + "  badge-light-danger";
      } else if (tp === "Yes") {
        return res + " badge-light-info px-6";
      } else if (tp === "No") {
        return res + "  badge-light-danger  px-6";
      } else if (tp === "Api") {
        return res + " badge-light-primary px-6";
      } else if (tp === "Manual" || tp === "Single") {
        return res + " badge-light-info";
      } else if (tp === "Closed") {
        return res + " badge-light-danger";
      } else if (tp === "Cancelled") {
        return res + " badge-light-danger";
      } else if (tp === "Partially received") {
        return res + " badge-light-warning";
      } else if (tp === "Fully received") {
        return res + " badge-light-primary";
      } else if (tp === "Draft") {
        return res + " badge-light-warning";
      } else if (tp === "Internal") {
        return res + " badge-light-warning";
      } else if (tp === "External" || tp === "Batch") {
        return res + " badge-light-primary";
      } else if (tp === "Out for pickup" || tp === "Pickup Assigned") {
        return res + " badge-light-info";
      } else if (tp === "Out for return") {
        return res + " badge-light-warning";
      } else if (tp === "Assigned" || tp === "Parent") {
        return res + " badge-light-success";
      } else if (tp === "Requested") {
        return res + " badge-light-primary";
      } else if (tp === "Pending Confirmation") {
        return res + " badge-light-warning";
      } else if (tp === "Split") {
        return res + " badge-light-warning";
      } else if (tp === "Rest") {
        return res + " badge-light-warning";
      } else if (tp === "IQ") {
        return res + " badge-light-danger px-6";
      } else if (tp === "Soap") {
        return res + " badge-light-primary";
      } else if (tp === "Pre defined generation") {
        return res + " badge-light-info";
      } else if (tp === "Don't generate") {
        return res + " badge-light-danger";
      } else if (tp === "Failed") {
        return res + " badge-light-danger";
      } else if (tp === "Finished") {
        return res + " badge-light-success";
      } else if (tp === "Call Assigned") {
        return res + " badge-light-success";
      } else if (tp === "No Response") {
        return res + " badge-light-info";
      } else if (tp === "On queue") {
        return res + " badge-light-warning";
      }
      //----
      else if (tp === "Call Successfully Done") {
        return res + " badge-light-primary";
      } else if (tp === "Mobile Switched off") {
        return res + " badge-light-info";
      } else if (tp === "Refused/ Cancelled") {
        return res + " badge-light-danger";
      } else if (tp === "Wrong Number/Wrong Person") {
        return res + " badge-light-danger";
      } else if (tp === "Customer Not Available") {
        return res + " badge-light-danger";
      } else if (tp === "Unreachable") {
        return res + " badge-light-danger";
      } else if (tp === "Invalid Number") {
        return res + " badge-light-danger";
      } else if (tp === "Call Disconnected") {
        return res + " badge-light-danger";
      } else if (tp === "Call Back Later") {
        return res + " badge-light-info";
      } else if (tp === "Mobile Out of Service") {
        return res + " badge-light-info";
      } else if (tp === "Incorrect phone number") {
        return res + " badge-light-danger";
      } else if (tp === "Incomplete address") {
        return res + " badge-light-warning";
      } else if (tp === "Waiting for Confirmation") {
        return res + " badge-light-warning";
      } else if (tp === "Scheduled Delivery") {
        return res + " badge-light-primary";
      } else if (tp === "Accidental Order") {
        return res + " badge-light-warning";
      } else if (tp === "Cancelled by Client") {
        return res + " badge-light-warning";
      } else if (tp === "Duplicate order") {
        return res + " badge-light-warning";
      } else if (tp === "Expensive") {
        return res + " badge-light-primary";
      } else if (tp === "No longer needed") {
        return res + " badge-light-info";
      } else if (tp === "Unaware of the order") {
        return res + " badge-light-info";
      } else if (tp === "Assigned to driver") {
        return res + " badge-light-info";
      } else if (tp === "Out for collection") {
        return res + " badge-light-info";
      } else if (tp === "Call was Assigned") {
        return res + " badge-light-warning";
      } else if (tp === "On Hold") {
        return res + " badge-light-danger";
      } else if (tp === "Pending Audit") {
        return res + " badge-light-warning";
      } else if (tp === "CR") {
        return res + " badge-light-info px-6";
      } else if (tp === "VPO") {
        return res + " badge-light-primary px-5";
      } else if (tp === "INXS") {
        return res + " badge-light-warning px-5";
      } else if (tp === "PREPAID") {
        return res + " badge-light-primary";
      } else if (tp === "COD") {
        return res + " badge-light-danger px-6";
      } else if (tp === "Enabled") {
        return res + " badge-light-success";
      } else if (tp === "Disabled") {
        return res + " badge-light-danger";
      } else if (tp === "Fulfillment") {
        return res + " badge-light-danger";
      } else if (tp === "Delivery") {
        return res + " badge-light-warning";
      } else if (tp === "Fulfillment and Delivery") {
        return res + " badge-light-info";
      } else if (tp === "Storage") {
        return res + " badge-light-primary";
      } else if (tp === "Seller") {
        return res + " badge-light-success";
      } else if (tp === "Aggregator") {
        return res + " badge-light-primary";
      } else if (tp === "Going on") {
        return res + " badge-light-primary";
      } else if (tp === "Subscription") {
        return res + " badge-light-primary";
      } else if (tp === "Processing") {
        return res + " badge-light-warning";
      } else if (tp === "Reading") {
        return res + " badge-light-info";
      } else if (tp === "Allow") {
        return res + " badge-light-primary";
      } else if (tp === "Deny") {
        return res + " badge-light-danger";
      } else if (tp === "Allow, Deny") {
        return res + " badge-light-info";
      } else if (tp === "Pre-alert Initiated") {
        return res + " badge-light-warning";
      } else if (tp === "Prepaid") {
        return res + " badge-light-warning";
      } else if (("" + tp).toLowerCase() === "transit to dc") {
        return res + " badge-light-warning";
      } else if (("" + tp).toLowerCase() === "arrived dc") {
        return res + " badge-light-primary";
      } else if (tp === "Collected") {
        return res + " badge-light-success";
      } else if (tp === "Processed") {
        return res + " badge-light-success";
      } else if (tp === "Unsubscribed") {
        return res + " badge-light-secondary text-dark";
      } else if (tp === "Out for delivery") {
        return res + " badge-light-info";
      } else if (tp === "Pending Allocation") {
        return res + " badge-light-primary  text-dark";
      } else if (tp === "No Errors") {
        return res + " success--text font-weight-bolder";
      } else if (tp === "Integration couldn't find") {
        return res + " red--text text-center font-weight-bolder";
      } else if (tp === "Call Rejected") {
        return res + " badge-light-warning";
      } else if (tp === "Future Delivery") {
        return res + " badge-light-info";
      } else if (tp === "Country") {
        return res + " badge-light-primary";
      } else if (tp === "State") {
        return res + " badge-light-success";
      } else if (tp === "City") {
        return res + " badge-light-warning";
      } else if (tp === "Area") {
        return res + " badge-light-info";
      } else if (tp === "Completed") {
        return res + " badge-light-success";
      } else if (tp === "Partially Completed") {
        return res + " badge-light-info";
      } else if (tp === "Allocated") {
        return res + " badge-light-info";
      } else if (tp === "Partially Allocated") {
        return res + " badge-light-warning";
      } else if (tp === "Custom") {
        return res + " badge-light-warning";
      } else if (tp === "Pending Assignment") {
        return res + " badge-light-info";
      } else if (tp === "Out of Stock") {
        return res + " badge-light-danger";
      } else if (tp === "Process Failed") {
        return res + " badge-light-danger";
      } else if (tp === "Process Picked") {
        return res + " badge-light-success";
      } else if (tp === "Under clearance") {
        return res + " badge-light-warning";
      } else if (tp === "Assignment Failed") {
        return res + " badge-light-danger";
      } else if (tp === "Declared") {
        return res + " badge-light-primary";
      } else if (tp === "Pending Adjustment") {
        return res + " badge-light-warning";
      } else if (tp === "Approved Adjustment") {
        return res + " badge-light-info";
      } else if (tp === "Cancelled Adjustment") {
        return res + " badge-light-danger";
      } else if (tp === "Pending Location Movement") {
        return res + " badge-light-warning";
      } else if (tp === "Approved Location Movement") {
        return res + " badge-light-info";
      } else if (tp === "Cancelled Location Movement") {
        return res + " badge-light-danger";
      } else if (tp === "Validated") {
        return res + " badge-light-primary";
      } else if (tp === "Validation Failed") {
        return res + " badge-light-danger";
      } else if (tp === "Sync Failed") {
        return res + " badge-light-danger";
      } else if (tp === "Received") {
        return res + " badge-light-success";
      } else if (tp === "Open") {
        return res + " badge-light-primary";
      } else if (tp === "Resolved") {
        return res + " badge-light-success";
      } else if (tp === "Unresolved") {
        return res + " badge-light-warning";
      } else if (tp === "Order") {
        return res + " badge-light-primary";
      } else if (tp === "Sku") {
        return res + " badge-light-success";
      } else if (tp === "Asn") {
        return res + " badge-light-info";
      } else if (tp === "Sorted") {
        return res + " badge-light-success";
      } else if (tp === "Rejected") {
        return res + " badge-light-danger";
      } else if (tp === "Picked Up") {
        return res + " badge-light-info";
      } else if (tp === "Return") {
        return res + " badge-light-warning";
      } else if (tp === "DR") {
        return res + " badge-light-warning";
      } else if (tp === "RR") {
        return res + " badge-light-success";
      } else if (tp === "Shipment") {
        return res + " badge-light-info";
      } else if (tp === "Route") {
        return res + " badge-light-primary";
      } else if (tp === "Departed Origin") {
        return res + " badge-light-primary";
      } else if (tp === "Scheduled") {
        return res + " badge-light-success";
      } else if (tp === "Test Mode") {
        return res + " badge-light-warning";
      } else {
        return res;
      }
    },
  },
};
</script>
